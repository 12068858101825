<template>
  <div class="app-container">
    <RouterView />
  </div>
</template>

<style lang="scss">
@import 'src/assets/styles/base';

.app-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100vh;
}
</style>
